import React from 'react'
import './menuStyle.css'
import { useLayoutEffect } from 'react';
import $ from "jquery";
import { Box, styled, Grid, Button, FormLabel, Snackbar, Alert } from '@mui/material';
import { useRef } from 'react';
import { useEffect } from 'react';
import { Breadcrumb, SimpleCard } from 'app/components';
import MenuItemAccordian from './MenuItemAccordian';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMenuItems, getAMenuItems, GetPostMeta, GetPosts, saveEditMenuItems } from 'store/actions/postAction';
import MenuInput from './MenuInput';
import { SUCCESS_MESSAGE_CLEAR } from 'store/types/userTypes';
import Swal from 'sweetalert2'

const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
}));

const MenuDesign = () => {
    const {
        cloudinary,
        posts,
        postmeta,
        current,
        successMessage,
        errorMessage,
        menuDesign
    } = useSelector((state) => state.Posts);

    // console.log(menuDesign);
    // console.log(successMessage)
    const dispatch = useDispatch();
    const textareaRef = useRef(null);
    const appendData = useRef(null);
    // const [newItem, setNewItem] = useState(false);
    const [menuJson, setMenuJson] = useState('');
    const [isClickedSaveBTN, setIsClickedSaveBTN] = useState(false);
    const [callbackResponse, setCallbackResponse] = useState({ type: "info", message: "" });
    const [editMenuItem, setEditMenuItem] = useState({});

    const [dynamicData, setDynamicData] = useState([])

    const [menuData, setMenuData] = useState({
        menuName: 'Create new menu',
        menuStructure: '[]',
        itemId: ''
    });

    let vertical = 'top';
    let horizontal = 'right';

    useLayoutEffect(() => {
        $(document).ready(() => {
            const script2 = document.createElement("script");
            script2.src = "/jquery/script.js";
            script2.async = true;
            document.body.appendChild(script2);

            return () => {
                console.log("--------[67]-----------");
                document.body?.removeChild(script2);
            };
        });
    }, []);

    useLayoutEffect(() => {
        const time = setTimeout(() => {
            $(document).ready(() => {
                const script3 = document.createElement("script");
                script3.src = "/jquery/jquery.nestable.js";
                script3.async = true;
                document.body.appendChild(script3);
                console.log(81)
                return () => {
                    console.log("--------[83]-----------");
                    document.body?.removeChild(script3);
                };
            });
        }, 1000);

        return () => {
            clearTimeout(time);
        };
    }, [dynamicData]);

    useEffect(() => {
        $(textareaRef.current).val();
        dispatch(GetPostMeta());
        dispatch(GetPosts());
        dispatch(getAMenuItems());
    }, []);


    const getTextareaValue = () => {
        if (textareaRef.current) {
            let value = textareaRef.current.value || "[]";
            value = JSON.parse(value).filter(sv => sv.deleted === 0);
            // console.log(106, JSON.parse(value));
            setMenuData(pre => ({ ...pre, "menuStructure": JSON.stringify(value) }));
            setIsClickedSaveBTN(true)
        }
    };



    useEffect(() => {
        if (Object.keys(editMenuItem).length > 0) {
            console.log(editMenuItem);
            if (!editMenuItem._id) return;
            const { menuStructure } = editMenuItem
            const parseData = JSON.parse(menuStructure);
            setDynamicData(parseData)
            setMenuData(pre => ({
                ...pre,
                menuName: editMenuItem.menuName, itemId: editMenuItem._id
            }))
            // update the menu json
            if (textareaRef.current) {
                console.log(125, menuStructure);
                textareaRef.current.value = menuStructure;
            }
        } else {
            setDynamicData([])
        }
    }, [editMenuItem])


    useEffect(() => {
        if (menuData && Object.keys(menuData).length > 0
            && (menuData.menuStructure === '[]' || menuData.menuStructure === "")
            && isClickedSaveBTN) {
            console.log("138");
            setCallbackResponse({ type: "error", message: "Add items to your menu" })
            setIsClickedSaveBTN(!isClickedSaveBTN);
            return;
        }
        if (menuData && Object.keys(menuData).length > 0 && menuData.menuName.length === 0 && isClickedSaveBTN) {
            console.log("144");
            setCallbackResponse({ type: "error", message: "Enter the menu name" })
            setIsClickedSaveBTN(!isClickedSaveBTN);
            return;
        }


        if (menuData && Object.keys(menuData).length > 0 && menuData.menuName.length !== 0 && isClickedSaveBTN) {
            console.log("152");
            const isPresent = menuDesign.some((menu) => menu.menuName === menuData.menuName)
            const idUpdate = editMenuItem && Object.keys(editMenuItem).length > 0
            if (!idUpdate && isPresent) {
                setCallbackResponse({ type: "error", message: "Menu name already exists" });
                setIsClickedSaveBTN(!isClickedSaveBTN);
                return;
            }
        }

        if (isClickedSaveBTN) {
            console.log("163");
            if (menuData.itemId === "new") {
                delete menuData.itemId;
            }
            if (menuData.menuStructure && Array.isArray(menuData.menuStructure) && menuData.menuStructure.length === 0) {
                setCallbackResponse({ type: "error", message: "Add items to your menu" })
                setIsClickedSaveBTN(!isClickedSaveBTN);
                return;
            }
            console.log(menuData);
            dispatch(saveEditMenuItems(menuData));
            setIsClickedSaveBTN(!isClickedSaveBTN);
            console.log(174, "<-------------------------------------------->");
        }
    }, [menuJson, isClickedSaveBTN]);

    useEffect(() => {
        if (successMessage && successMessage?.create?.length) {
            setCallbackResponse({ type: "success", message: successMessage.create });
            dispatch(getAMenuItems());
            dispatch({ type: SUCCESS_MESSAGE_CLEAR });
        }
        if (successMessage && successMessage?.delete?.length) {
            setCallbackResponse({ type: "success", message: successMessage.delete });
            setEditMenuItem({});
            setDynamicData([]);
            setMenuData({
                menuName: '',
                menuStructure: '[]',
                itemId: ''
            })
            dispatch(getAMenuItems());

            dispatch({ type: SUCCESS_MESSAGE_CLEAR });
        }
    }, [successMessage]);

    const renderMenuItem = (item, index) => {
        if (!item || Object.keys(item).length === 0) return null;

        return (
            <li
                className="dd-item"
                key={item.id || index}
                data-id={item.id}
                data-name={item.name}
                data-slug={item.slug}
                // data-new={item.new || index + 1}
                data-new={item.data}
                data-deleted={item.deleted || 0}
            >
                {/* {item.children && item.children.length > 0 && <button data-action="collapse" type="button" >Collapse</button>} */}
                {/* {item.children && item.children.length > 0 && <button data-action="expand" type="button">Expand</button>} */}
                <div className="dd-handle">{item.name}</div>
                <span
                    className="button-delete btn btn-danger btn-xs pull-right"
                    data-owner-id={item.id}
                >
                    <i className="fa fa-times" aria-hidden="true"></i>
                </span>
                <span
                    className="button-edit btn btn-success btn-xs pull-right"
                    data-owner-id={item.id}
                >
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                </span>

                {/* Render children if they exist */}
                {item.children && item.children.length > 0 && (
                    <ol className="dd-list">
                        {item.children.map((child, childIndex) =>
                            renderMenuItem(child, childIndex)
                        )}
                    </ol>
                )}
            </li>
        );
    };

    return (
        <Container >
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: "Dashboard", path: "" }, { name: "Menu Design" }]} />
            </Box>

            <Snackbar anchorOrigin={{ vertical, horizontal }} open={callbackResponse.message} autoHideDuration={6000} onClose={() => setCallbackResponse({ type: "", message: "" })}>
                <Alert
                    severity={callbackResponse.type}
                    variant="filled"
                    sx={{ width: '300px' }}
                >
                    {callbackResponse.message}
                </Alert>
            </Snackbar>

            <SimpleCard title="Add menu items">
                <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={4} xs={12} sx={{ mt: 2 }}>
                        <MenuItemAccordian appendRef={appendData} textareaRef={textareaRef} editMenuItem={editMenuItem} setDynamicData={setDynamicData} />
                        <form className="" id="menu-editor" style={{ display: "none" }}>
                            <label className="form-label">Editing <span id="currentEditName" /></label>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="editInputName"
                                    placeholder="Item name"
                                    required=""
                                />
                            </div>
                            <div>&nbsp;</div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="editInputSlug"
                                    placeholder="item-slug"
                                />
                            </div>
                            <div>&nbsp;</div>
                            <button className="btn btn-info" id="editButton">
                                Edit
                            </button>
                        </form>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={12} sx={{ mt: 2 }}>
                        {/* <label className="form-label">Name</label> */}
                        <MenuInput textareaRef={textareaRef} menuData={menuData} setMenuData={setMenuData} menuDesign={menuDesign} setEditMenuItem={setEditMenuItem} setDynamicData={setDynamicData} editMenuItem={editMenuItem} />

                        <div>&nbsp;</div>
                        <label className="form-label">Structure</label>
                        {/* <div className="dd nestable" id="nestable">
                            <ol className="dd-list" ref={appendData}>
                                {dynamicData && dynamicData.map((data, ind) => {
                                    return data && Object.keys(data).length > 0 && (
                                        <li class="dd-item" key={ind + 1}
                                            // data-id={`${'new-' + ind + 1}`}
                                            data-id={`${data.id}`}
                                            data-name={`${data.name}`}
                                            data-slug={`${data.slug}`}
                                            // data-new={`${ind + 1}`}
                                            data-new={`${data.id}`}
                                            data-deleted="0">
                                            <div class="dd-handle">
                                                {data.name}
                                            </div>
                                            <span class="button-delete btn btn-danger btn-xs pull-right"
                                                data-owner-id={`${data.id}`}>
                                                <i class="fa fa-times" aria-hidden="true"></i>
                                            </span>
                                            <span class="button-edit btn btn-success btn-xs pull-right"
                                                data-owner-id={`${data.id}`}>
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </span>
                                        </li>
                                    )
                                })}
                            </ol>
                        </div> */}
                        <div className="dd nestable" id="nestable">
                            <ol className="dd-list" ref={appendData}>
                                {dynamicData && dynamicData.map((data, ind) => renderMenuItem(data, ind))}
                            </ol>
                        </div>

                        <div className='d-flex pull-right'>
                            <div>&nbsp;</div>
                            <button className='btn btn-primary mt-4' onClick={getTextareaValue} style={{ width: '200px' }} >Save Menu</button>
                            {editMenuItem && Object.keys(editMenuItem).length > 0 &&
                                <button className='btn btn-danger mt-4' onClick={() => {
                                    Swal.fire({
                                        title: "Are you sure?",
                                        showCancelButton: true,
                                        text: "This action cannot be undone!",
                                        confirmButtonText: 'Yes',
                                        cancelButtonText: `No`,
                                        cancelButtonColor: "#EE7364"
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            dispatch(deleteMenuItems(editMenuItem._id))
                                        }
                                    });
                                }} style={{ width: '200px', marginLeft: '15px' }} >Delete Menu</button>

                            }
                        </div>
                    </Grid>
                </Grid>

                <div className="row output-container d-flex justify-content-end" >
                    <div className="col-md-offset-1 col-md-10">
                        <h2 className="text-center">Output:</h2>
                        <form className="form">
                            <textarea
                                className="form-control"
                                id="json-output"
                                rows={5}
                                defaultValue={""}
                                disabled
                                ref={textareaRef}
                                onChange={(e) => console.log(e.target.value)}
                            />
                        </form>
                    </div>
                </div>
            </SimpleCard>
        </Container>
    )
}

export default MenuDesign