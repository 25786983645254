export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS'
export const POST_LOGIN_ERROR = 'POST_LOGIN_ERROR'

export const SUCCESS_MESSAGE_CLEAR = 'SUCCESS_MESSAGE_CLEAR'
export const ERROR_CLEAR = 'ERROR_CLEAR'

export const POST_LOGOUT_SUCCESS = 'POST_LOGOUT_SUCCESS'
export const POST_LOGOUT_FAIL = 'POST_LOGOUT_FAIL'

export const POST_LOGOUT_FAIL_CLEAR = 'POST_LOGOUT_FAIL_CLEAR'
export const POST_LOGOUT_SUCCESS_CLEAR = 'POST_LOGOUT_SUCCESS_CLEAR'

export const POST_CREATE_SUCCESS = 'POST_CREATE_SUCCESS'
export const POST_CREATE_FAIL = 'POST_CREATE_FAIL'

export const POST_UPDATE_SUCCESS = 'POST_UPDATE_SUCCESS'
export const POST_UPDATE_FAIL = 'POST_UPDATE_FAIL'

export const POST_DELETE_SUCCESS = 'POST_DELETE_SUCCESS'
export const POST_DELETE_FAIL = 'POST_DELETE_FAIL'

export const POST_GET_SUCCESS = 'POST_GET_SUCCESS'
export const POST_GET_FAIL = 'POST_GET_FAIL'

export const POSTMETA_GET_SUCCESS = 'POSTMETA_GET_SUCCESS'
export const POSTMETA_GET_FAIL = 'POSTMETA_GET_FAIL'

export const POSTMETA_CREATE_SUCCESS = 'POSTMETA_CREATE_SUCCESS'
export const POSTMETA_CREATE_FAIL = 'POSTMETA_CREATE_FAIL'

export const POSTMETA_UPDATE_SUCCESS = 'POSTMETA_UPDATE_SUCCESS'
export const POSTMETA_UPDATE_FAIL = 'POSTMETA_UPDATE_FAIL'

export const POSTMETA_DELETE_SUCCESS = 'POSTMETA_DELETE_SUCCESS'
export const POSTMETA_DELETE_FAIL = 'POSTMETA_DELETE_FAIL'


export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL'


export const COUDENARY_CONFIG_SUCCESS = 'COUDENARY_CONFIG_SUCCESS'
export const COUDENARY_CONFIG_FAIL = 'COUDENARY_CONFIG_FAIL'


export const SAVE_MENU_ITEMS_SUCCESS = 'SAVE_MENU_ITEMS_SUCCESS'
export const SAVE_MENU_ITEMS_FAILED = 'SAVE_MENU_ITEMS_FAILED'


export const GET_MENU_ITEMS_SUCCESS = 'GET_MENU_ITEMS_SUCCESS'
export const GET_MENU_ITEMS_FAILED = 'GET_MENU_ITEMS_FAILED'


export const DELETE_MENU_ITEMS_SUCCESS = 'DELETE_MENU_ITEMS_SUCCESS'
export const DELETE_MENU_ITEMS_FAILED = 'DELETE_MENU_ITEMS_FAILED'