import * as React from 'react';
import { Box, InputLabel, MenuItem, FormControl, Select, TextField } from '@mui/material';
import { useEffect } from 'react';

const MenuInput = ({ textareaRef, menuData, setMenuData, menuDesign, setEditMenuItem, setDynamicData, editMenuItem }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("here", menuData, { name, value });
        setMenuData((prev) => ({ ...prev, [name]: value, itemId: "new", menuStructure: '[]' }));
    };

    const handleSelectChange = (selectedItem) => {
        textareaRef.current.value = "";
        if (!selectedItem._id) {
            setEditMenuItem(selectedItem);
            setMenuData({
                menuName: 'Create new menu',
                menuStructure: '[]',
                itemId: ''
            });
            setDynamicData([]);
        } else {
            setEditMenuItem(selectedItem);
        }
    };

    useEffect(() => {
        if (menuDesign && editMenuItem) {
            const filterMenuData = menuDesign.find((menu) => menu.menuName === menuData.menuName);
            if (filterMenuData) {
                setEditMenuItem(filterMenuData);
            }
        }
    }, [menuDesign]);

    // console.log({ editMenuItem });
    // console.log({ menuData });
    // console.log({ menuDesign });

    return (
        <Box className="form-group d-flex gap-2">
            <FormControl fullWidth sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                <InputLabel id="select-menu-label">Select Menu</InputLabel>
                <Select
                    labelId="select-menu-label"
                    id="select-menu-label"
                    value={editMenuItem || ''}
                    label="Select menu"
                    name="menuLinkWith"
                    onChange={(e) => handleSelectChange(e.target.value)}
                    sx={{ width: "48%" }}
                >
                    {menuDesign && Array.isArray(menuDesign) && menuDesign.map((data, i) => (
                        <MenuItem key={i} value={data}>
                            {data.menuName}
                        </MenuItem>
                    ))}
                </Select>

                <TextField
                    id="outlined-basic"
                    label="Menu Name"
                    variant="outlined"
                    type="text"
                    name="menuName"
                    value={Object.keys(menuData).length > 0 && menuData?.itemId ? menuData?.menuName : ""}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                    autoComplete="off"
                    InputLabelProps={{
                        shrink: true, // Ensure the label shrinks
                    }}
                    sx={{ width: "48%" }} />
            </FormControl>
        </Box>
    );
};

export default MenuInput;