import {
    POST_CREATE_SUCCESS,
    POST_CREATE_FAIL,
    POST_GET_SUCCESS,
    POST_GET_FAIL,
    POST_UPDATE_SUCCESS,
    POST_UPDATE_FAIL,
    POST_DELETE_SUCCESS,
    POST_DELETE_FAIL,
    POSTMETA_CREATE_SUCCESS,
    POSTMETA_CREATE_FAIL,
    POSTMETA_UPDATE_SUCCESS,
    POSTMETA_UPDATE_FAIL,
    POSTMETA_DELETE_FAIL,
    POSTMETA_DELETE_SUCCESS,
    POSTMETA_GET_SUCCESS,
    POSTMETA_GET_FAIL,
    GET_CATEGORIES_FAIL,
    GET_CATEGORIES_SUCCESS,
    COUDENARY_CONFIG_SUCCESS,
    COUDENARY_CONFIG_FAIL,
    SAVE_MENU_ITEMS_FAILED,
    SAVE_MENU_ITEMS_SUCCESS,
    GET_MENU_ITEMS_FAILED,
    GET_MENU_ITEMS_SUCCESS,
    DELETE_MENU_ITEMS_FAILED,
    DELETE_MENU_ITEMS_SUCCESS
} from "../types/postTypes";

import axios from "axios";

import { SERVER_URL } from '../../config/keys';
import { current } from "@reduxjs/toolkit";

export const userAuth = () => {
    axios.interceptors.request.use(
        (config) => {
            // config.headers.authorization = `Bearer ${token}`;
            config.headers = {
                ...config.headers,
                'Content-Type': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*'
            };

            config.withCredentials = true
            // console.log(config)
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};

const axiosInstance = axios.create({
    baseURL: `${SERVER_URL}`,
    withCredentials: true,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});


export const getCategories = () => {
    return async dispatch => {
        try {
            const response = await axios.get(`${SERVER_URL}/api-v1/client/get-client-data`);
            dispatch({
                type: GET_CATEGORIES_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    categories: response.data.categories
                }
            })
        } catch (error) {
            let data = error?.response?.data?.message
            dispatch({
                type: GET_CATEGORIES_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}

export const GetPosts = (data) => {
    userAuth();
    return async (dispatch) => {
        try {
            const response = await axios.post(`${SERVER_URL}/api-v1/post/get-posts`, {});
            // console.log(response)
            dispatch({
                type: POST_GET_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    posts: response.data.posts
                }
            })
        } catch (error) {
            let data = error?.response?.data?.message
            dispatch({
                type: POST_GET_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}

// `attachedData` Contains post type block metadata (`attachedTo` and `buildForm`)
export const CreatePost = ({ selectedImage, postData, customField, seoData, attachedData, collectedFormData }) => {
    // userAuth();
    /* console.log({ selectedImage, postData, customField, seoData, attachedData, collectedFormData })
    return; */

    let postCategory = postData && postData.postCategory ? [...new Set(postData.postCategory)] : [];
    let postTag = postData && postData.postTag ? [...new Set(postData.postTag)] : [];

    const formData = new FormData();
    formData.append('file', selectedImage);
    formData.append('author', postData.author);
    formData.append('postTitle', postData.postTitle);
    formData.append('postSlug', postData.postSlug);
    formData.append('postExcerpt', postData.postExcerpt);
    formData.append('postContent', postData.postContent);
    formData.append('postType', postData.postType);
    formData.append('postStatus', postData.postStatus);
    formData.append('postCategory', JSON.stringify(Array.from(postCategory)));
    formData.append('postTag', JSON.stringify(Array.from(postTag)));
    formData.append('customField', JSON.stringify(Array.from(customField)));
    formData.append('seoData', JSON.stringify(Array.from(seoData)));
    formData.append('attachedTo', JSON.stringify(Array.from(attachedData)));
    formData.append('collectedFormData', JSON.stringify(Array.from(collectedFormData)));

    // console.log(formData); return;

    return async (dispatch) => {
        try {
            // let response = await axios.post(`${SERVER_URL}/api-v1/post/create`, formData);
            let response = await axiosInstance.post(`/api-v1/post/create`, formData);
            // console.log(response)
            dispatch({
                type: POST_CREATE_SUCCESS,
                payload: {
                    posts: response.data.posts,
                    successMessage: response.data.message,
                    current: response.data.current
                }
            })
        } catch (error) {
            // console.log(error)
            let data = error?.response?.data?.message
            dispatch({
                type: POST_CREATE_FAIL,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const CreateMediaPost = ({ acceptedFiles, author }) => {
    // userAuth();
    const formData = new FormData();

    for (let index = 0; index < acceptedFiles.length; index++) {
        const file = acceptedFiles[index];
        formData.append(`files`, file);
    }
    formData.append('author', author);

    return async (dispatch) => {
        try {
            // let response = await axios.post(`${SERVER_URL}/api-v1/post/create`, formData);
            let response = await axiosInstance.post(`/api-v1/post/create-media`, formData);
            // console.log(response)
            dispatch({
                type: POST_CREATE_SUCCESS,
                payload: {
                    posts: response.data.posts,
                    successMessage: response.data.message,
                }
            })
        } catch (error) {
            // console.log(error)
            let data = error?.response?.data?.message
            dispatch({
                type: POST_CREATE_FAIL,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const GetMediaPosts = (data) => {
    userAuth();
    return async (dispatch) => {
        try {
            const response = await axios.post(`${SERVER_URL}/api-v1/post/get-media`, data);
            // console.log(response)
            dispatch({
                type: POST_GET_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    posts: response.data.posts
                }
            })
        } catch (error) {
            let data = error?.response?.data?.message
            dispatch({
                type: POST_GET_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}

export const UpdatePost = ({ selectedImage, postData, customField, seoData, attachedData, collectedFormData }) => {
    // userAuth();
    /* console.log(postData);
    console.log({ selectedImage, postData, customField, seoData, attachedData, collectedFormData }); */

    let postCategory = postData && postData.postCategory ? [...new Set(postData.postCategory)] : [];
    let postTag = postData && postData.postTag ? [...new Set(postData.postTag)] : [];

    const formData = new FormData();
    formData.append('_id', postData._id);
    formData.append('file', selectedImage);
    formData.append('author', postData.author);
    formData.append('postTitle', postData.postTitle);
    formData.append('postSlug', postData.postSlug);
    formData.append('postExcerpt', postData.postExcerpt);
    formData.append('postContent', postData.postContent);
    formData.append('postType', postData.postType);
    formData.append('postStatus', postData.postStatus);
    formData.append('postCategory', JSON.stringify(Array.from(postCategory)));
    formData.append('postTag', JSON.stringify(Array.from(postTag)));
    formData.append('customField', JSON.stringify(Array.from(customField)));
    formData.append('seoData', JSON.stringify(Array.from(seoData)));
    formData.append('attachedTo', JSON.stringify(Array.from(attachedData)));
    formData.append('collectedFormData', JSON.stringify(Array.from(collectedFormData)));

    return async (dispatch) => {
        try {
            // const response = await axios.put(`${SERVER_URL}/api-v1/post/update-post`, data);
            let response = await axiosInstance.put(`/api-v1/post/update-post`, formData);
            // console.log(response)
            dispatch({
                type: POST_UPDATE_SUCCESS,
                payload: {
                    posts: response.data.posts,
                    successMessage: response.data.message,
                    current: response.data.current
                }
            })
        } catch (error) {
            let data = error.response.data.message;
            dispatch({
                type: POST_UPDATE_FAIL,
                payload: {
                    errorMessage: data,
                },
            });
        }
    }
}

export const DeletePost = (data) => {
    // console.log(data)
    return async (dispatch) => {
        try {
            userAuth();
            const response = await axios.post(`${SERVER_URL}/api-v1/post/delete-post`, data);
            // console.log(response)
            dispatch({
                type: POST_DELETE_SUCCESS,
                payload: {
                    posts: response.data.posts,
                    successMessage: response.data.message,
                }
            })
        } catch (error) {
            let data = error.response.data.message;
            dispatch({
                type: POST_DELETE_FAIL,
                payload: {
                    error: data,
                },
            });
        }
    }
}

export const GetPostMeta = (data) => {
    // console.log("getPostMeta", data)
    return async (dispatch) => {
        try {
            userAuth();
            const response = await axios.post(`${SERVER_URL}/api-v1/post/get-postmeta`, data);
            dispatch({
                type: POSTMETA_GET_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    postmeta: response.data.postmeta,
                    allPostMeta: response.data.allPostMeta
                }
            })
        } catch (error) {
            let data = error?.response?.data?.message
            dispatch({
                type: POSTMETA_GET_FAIL,
                payload: {
                    error: data
                }
            })
        }
    }
}

export const CreatePostMeta = (data) => {
    return async (dispatch) => {
        try {
            userAuth();
            let response = await axios.post(`${SERVER_URL}/api-v1/post/create-postmeta`, data);
            dispatch({
                type: POSTMETA_CREATE_SUCCESS,
                payload: {
                    postmeta: response.data.postmeta,
                    successMessage: response.data.message,
                    current: null,
                }
            })
        } catch (error) {
            // console.log(error)
            let data = error?.response?.data?.message
            dispatch({
                type: POSTMETA_CREATE_FAIL,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const CopyPost = (data) => {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${SERVER_URL}/api-v1/post/copy-post`, data);
            dispatch({
                type: POST_CREATE_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                }
            })
        } catch (error) {
            let data = error?.response?.data?.message
            dispatch({
                type: POST_CREATE_FAIL,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const UpdatePostMeta = (data) => {
    return async (dispatch) => {
        try {
            userAuth();
            let response = await axios.put(`${SERVER_URL}/api-v1/post/update-postmeta`, data);
            // console.log(response)
            dispatch({
                type: POSTMETA_UPDATE_SUCCESS,
                payload: {
                    postmeta: response.data.postmeta,
                    successMessage: response.data.message,
                }
            })
        } catch (error) {
            // console.log(error)
            let data = error?.response?.data?.message
            dispatch({
                type: POSTMETA_UPDATE_FAIL,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const DeletePostMeta = (data) => {
    // console.log(data)
    return async (dispatch) => {
        try {
            userAuth();
            const response = await axios.post(`${SERVER_URL}/api-v1/post/delete-postmeta`, data);
            // console.log(response)
            dispatch({
                type: POSTMETA_DELETE_SUCCESS,
                payload: {
                    posts: response.data.posts,
                    successMessage: response.data.message,
                }
            })
        } catch (error) {
            let data = error.response.data.message;
            dispatch({
                type: POSTMETA_DELETE_FAIL,
                payload: {
                    error: data,
                },
            });
        }
    }
}

export const configureCoudenary = () => {
    return async dispatch => {
        try {
            const response = await axios.get(`${SERVER_URL}/api-v1/post/cloudenary-config`);
            dispatch({
                type: COUDENARY_CONFIG_SUCCESS,
                payload: {
                    cloudenaryConfig: response.data.credentials,
                    successMessage: null,
                }
            });
        } catch (error) {
            let data = error.response.data.message;
            dispatch({
                type: COUDENARY_CONFIG_FAIL,
                payload: {
                    error: data,
                },
            });
        }
    }
};


export const saveEditMenuItems = (menuData) => {
    userAuth();
    return async dispatch => {
        try {
            const response = await axios.post(`${SERVER_URL}/api-v1/post/save-menu-desgn`, menuData);

            dispatch({
                type: SAVE_MENU_ITEMS_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                },
            });
        } catch (error) {
            let data = error.response?.data?.message || error.message;
            dispatch({
                type: SAVE_MENU_ITEMS_FAILED,
                payload: {
                    error: data,
                },
            });
        }
    }
}

export const getAMenuItems = () => {
    userAuth();
    return async dispatch => {
        try {
            const response = await axios.get(`${SERVER_URL}/api-v1/post/get-menu-data`);

            dispatch({
                type: GET_MENU_ITEMS_SUCCESS,
                payload: {
                    menuItems: response.data.menuDesign,
                    successMessage: response.data.message,
                },
            });
        } catch (error) {
            let data = error.response?.data?.message || error.message;
            dispatch({
                type: GET_MENU_ITEMS_FAILED,
                payload: {
                    error: data,
                },
            });
        }
    };
}


export const deleteMenuItems = (itemid) => {
    // console.log(itemid)
    // return;
    userAuth();
    return async dispatch => {
        try {
            const response = await axios.delete(`${SERVER_URL}/api-v1/post/delete-menu-item`, {
                data: { itemid }
            });

            dispatch({
                type: DELETE_MENU_ITEMS_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                },
            });
        } catch (error) {
            let data = error.response?.data?.message || error.message;
            dispatch({
                type: DELETE_MENU_ITEMS_FAILED,
                payload: {
                    error: data,
                },
            })
        }
    };
};