import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

import {
    Box,
    Divider,
    Typography,
    AccordionSummary,
    AccordionDetails,
    Accordion
} from '@mui/material'
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
// import { useState } from 'react';

export default function MenuItemAccordian({ appendRef, textareaRef, editMenuItem, setDynamicData }) {
    const { posts, postmeta } = useSelector((state) => state.Posts);

    const [accordianData, setAccordianData] = useState([])
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const addMenuItem = (addMenuItem) => {
        const { postSlug, postTitle, _id } = addMenuItem
        const currentItem = JSON.parse(textareaRef.current.value || "[]") || [];
        console.log(currentItem);

        // const newTitle = `<li class="dd-item" 
        //                     data-id=${'new-' + currentItem.length + 1} 
        //                     data-name= ${postTitle}
        //                     data-slug=${postSlug} 
        //                     data-new=${currentItem.length + 1} 
        //                     data-deleted="0">
        //                     <div class="dd-handle">
        //                         ${postTitle}
        //                     </div> 
        //                     <span class="button-delete btn btn-danger btn-xs pull-right" 
        //                             data-owner-id=${'new-' + currentItem.length + 1}>
        //                         <i class="fa fa-times" aria-hidden="true"></i> 
        //                     </span>
        //                     <span class="button-edit btn btn-success btn-xs pull-right" 
        //                             data-owner-id=${'new-' + currentItem.length + 1}>
        //                         <i class="fa fa-pencil" aria-hidden="true"></i>
        //                     </span>
        //                 </li>`;

        console.log(_id)
        currentItem.push({ "deleted": 0, "new": _id/* currentItem.length + 1 */, "slug": postSlug, "name": postTitle, "id": _id/* `${'new-' + currentItem.length + 1}` */ })

        // if (appendRef.current) {
        //     appendRef.current.innerHTML += newTitle;
        // }

        if (textareaRef.current) {
            setDynamicData(currentItem)
            textareaRef.current.value = JSON.stringify(currentItem);
        }

    }


    useEffect(() => {
        if (posts && posts.length) {
            const allPostTypes = [...new Set(posts.map(post => post.postType))]
                .filter(postType => postType !== "Block" && postType !== "Media");

            const organizedData = posts
                .filter(post => allPostTypes.includes(post.postType))
                .reduce((acc, post) => {
                    const existingEntry = acc.find(entry => Object.keys(entry)[0] === post.postType);

                    if (existingEntry) {
                        if (post.postStatus !== 'Draft')
                            existingEntry[post.postType].push(post);
                    } else {
                        if (post.postStatus !== 'Draft')
                            acc.push({ [post.postType]: [post] });
                    }
                    return acc;
                }, []);

            setAccordianData(organizedData);
        }
    }, [posts, postmeta]);
    return (
        <Box>
            {accordianData && accordianData.length > 0 && accordianData.map((item, key) => {
                return item && Object.keys(item).length > 0 &&
                    (<Accordion expanded={expanded === Object.keys(item)[0]} onChange={handleChange(Object.keys(item)[0])} key={key}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ flexShrink: 0 }}>
                                {Object.keys(item)[0]}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className='custom-scrollbar' sx={{ backgroundColor: "#FAFAFA", p: 1, maxHeight: '300px', overflowY: 'auto' }}>
                            {item[Object.keys(item)[0]].map(((accorItem, i) => {
                                return accorItem && Object.keys(accorItem).length > 0 && accorItem.postStatus !== 'Draft' && (
                                    <Box key={i} sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', p: 0.5, mb: 1 }}>
                                        <Typography sx={{ flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {accorItem.postTitle.slice(0, 59) + "..."}
                                        </Typography>
                                        <AddIcon onClick={() => addMenuItem(accorItem)} sx={{ cursor: 'pointer' }} />
                                    </Box>
                                )
                            }))}
                        </AccordionDetails>
                    </Accordion>)
            })}
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ color: 'green' }} >Add custom item</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id="addInputName"
                                placeholder="Item name"
                                required=""
                            />
                        </div>
                        <div>&nbsp;</div>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id="addInputSlug"
                                placeholder="item-slug"
                                required=""
                            />
                        </div>
                        <div>&nbsp;</div>
                        <button className="btn btn-primary" id="addButton">
                            {" "}
                            <i className="fa fa-plus-circle" aria-hidden="true" /> Add Item
                        </button>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}