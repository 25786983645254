import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import routes from './routes';

import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { GetCurrentUser, LogoutUser } from "../store/actions/userAction"

const App = () => {
  const content = useRoutes(routes);
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.location.pathname !== '/signin' && localStorage.getItem("auth") && localStorage.getItem("auth") !== true) {
      dispatch(GetCurrentUser())
      // .then(()=>{dispatch({ type: SUCCESS_MESSAGE_CLEAR })})
    }
  }, []);

  return (
    <SettingsProvider>
      <AuthProvider>
        <MatxTheme>
          <CssBaseline />
          {content}
        </MatxTheme>
      </AuthProvider>
    </SettingsProvider>
  );
};

export default App;
