import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import App from "./app/App";
import store from "../src/store/index";
import { Provider } from "react-redux";
import { configureCoudenary } from "store/actions/postAction";


store.dispatch(configureCoudenary())
// INCLUDE ALL THIRD PARTY LIBRARY CSS FILES
// import "perfect-scrollbar/css/perfect-scrollbar.css";
// import "react-big-calendar/lib/css/react-big-calendar.css";
// import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

// MAIN ROOT ELEMENT
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>

        <BrowserRouter>
            <SnackbarProvider anchorOrigin={{ horizontal: "right", vertical: "top" }}>
                <App />
            </SnackbarProvider>
        </BrowserRouter>

    </Provider>
);
