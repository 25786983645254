export const SUCCESS_MESSAGE_CLEAR = 'SUCCESS_MESSAGE_CLEAR'
export const ERROR_CLEAR = 'ERROR_CLEAR'

export const TERM_CREATE_SUCCESS = 'TERM_CREATE_SUCCESS'
export const TERM_CREATE_FAIL = 'TERM_CREATE_FAIL'

export const TERM_UPDATE_SUCCESS = 'TERM_UPDATE_SUCCESS'
export const TERM_UPDATE_FAIL = 'TERM_UPDATE_FAIL'

export const TERM_DELETE_SUCCESS = 'TERM_DELETE_SUCCESS'
export const TERM_DELETE_FAIL = 'TERM_DELETE_FAIL'

export const TERM_GET_SUCCESS = 'TERM_GET_SUCCESS'
export const TERM_GET_FAIL = 'TERM_GET_FAIL'

export const GET_ALL_COVARIAGE = 'GET_ALL_COVARIAGE'
export const GET_ALL_COVARIAGE_FAIL = 'GET_ALL_COVARIAGE_FAIL'


export const ADD_NEW_LOCATION = 'ADD_NEW_LOCATION'
export const ADD_NEW_LOCATION_FAIL = 'ADD_NEW_LOCATION_FAIL'


export const UPDATE_LOCATION = 'UPDATE_LOCATION'
export const UPDATE_LOCATION_FAIL = 'UPDATE_LOCATION_FAIL'


export const DELETE_LOCATION = 'DELETE_LOCATION'
export const DELETE_LOCATION_FAIL = 'DELETE_LOCATION_FAIL'
